<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>เปลี่ยนเวลาปิดอัตโนมัติจาก {{ OldAutoCloseDateTime }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12"
            ><v-switch
              color="success"
              v-model="switch1"
              :label="switch1 ? 'ปิดอัตโนมัติ' : 'ปิดด้วยตัวเอง'"
            ></v-switch
          ></v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
          <v-col cols="6"
            ><AutoCloseDatePicker v-model="EditAutoCloseDate"></AutoCloseDatePicker
          ></v-col>
          <v-col cols="6"
            ><AutoCloseTimePicker v-model="EditAutoCloseTime"></AutoCloseTimePicker
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import AutoCloseDatePicker from "@/components/Controls/cDatePicker";
import AutoCloseTimePicker from "@/components/Controls/cTimePicker";

export default {
  name: "AutoCloseDlg",

  mixins: [globalFunctionMixin],
  components: {
    AutoCloseDatePicker,
    AutoCloseTimePicker,
  },

  props: {
    value: Boolean,
    OldAutoCloseDateTime: String,
    OldIsAutoClose: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit(
        "NewAutoCloseDateTime",
        new Array(this.EditAutoCloseDate, this.EditAutoCloseTime)
      );
      this.$emit("NewIsAutoClose", this.switch1);
      this.$emit("input", false);
    },
  },
  data: () => ({
    switch1: true,
    EditAutoCloseDate: new Date().toISOString().substr(0, 10),
    EditAutoCloseTime: "14:00",
    // dialog: false,
  }),
};
</script>

<style></style>
