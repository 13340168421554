<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >เปลี่ยนเวลาเปิด จาก
        {{ getAbbrFullYearPeriodThai(OldOpenDateTime.split(" ")[0]) }} เวลา
        {{
          OldOpenDateTime == ""
            ? ""
            : OldOpenDateTime.split(" ")[1].substring(0, 5)
        }}
        น.</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="12"
            ><v-switch
              color="success"
              v-model="switch1"
              :label="switch1 ? 'เปิดอัตโนมัติ' : 'เปิดด้วยตัวเอง'"
            ></v-switch
          ></v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
          <v-col cols="6"
            ><OpenDatePicker v-model="EditOpenDate"></OpenDatePicker
          ></v-col>
          <v-col cols="6"
            ><OpenTimePicker v-model="EditOpenTime"></OpenTimePicker
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import OpenDatePicker from "@/components/Controls/cDatePicker";
import OpenTimePicker from "@/components/Controls/cTimePicker";

export default {
  name: "PeriodSettingDlg",
  mixins: [globalFunctionMixin],
  components: {
    OpenDatePicker,
    OpenTimePicker,
  },

  props: {
    value: Boolean,
    OldOpenDateTime: String,
    OldIsAutoOpen: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: function(value) {
      if (value) {
        this.switch1 = !this.OldIsAutoOpen;
      }
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit(
        "NewOpenDateTime",
        new Array(this.EditOpenDate, this.EditOpenTime)
      ); //this.EditOpenDate + " " + this.EditOpenTime);
      this.$emit("NewIsAutoOpen", this.switch1);
      this.$emit("input", false);
    },
  },
  data: () => ({
    // dialog: false,
    switch1: true,
    EditOpenDate: new Date().toISOString().substr(0, 10),
    EditOpenTime: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
  }),
};
</script>

<style></style>
