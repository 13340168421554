<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >เปลี่ยนงวดวันที่ จาก
        <strong class="ml-2 mr-2 indigo--text">{{
          getAbbrFullYearPeriodThai(OldPeriod)
        }}</strong>
        เป็น</v-card-title
      >
      <v-card-text>
        <PeriodDatePicker v-model="EditPeriod"></PeriodDatePicker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import PeriodDatePicker from "@/components/Controls/cDatePicker";

export default {
  name: "PeriodSettingDlg",
  mixins: [globalFunctionMixin],
  components: {
    PeriodDatePicker,
  },

  props: {
    value: Boolean,
    OldPeriod: String,
    // default: new Date().toISOString().substr(0, 10),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit("NewStatus", this.EditPeriod);
      this.$emit("input", false);
    },
  },
  data: () => ({
    EditPeriod: new Date().toISOString().substr(0, 10),
    // dialog: false,
  }),
};
</script>

<style></style>
