<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >เปิด/ปิดงวด จาก
        <strong class="indigo--text ml-2 mr-2">{{
          OldStatus == 0 ? "ปิดรับแทง" : "เปิดรับแทง"
        }}</strong>
        เป็น</v-card-title
      >
      <v-card-text>
        <v-switch
          color="success"
          v-model="switch1"
          :label="switch1 ? 'เปิดรับแทง' : 'ปิดรับแทง'"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OpenCloseDlg",
  components: {},

  props: {
    value: Boolean,
    OldStatus: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value: function(value) {
      if(value)
        this.switch1=!this.OldStatus;
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit("NewStatus", this.switch1);
      this.$emit("input", false);
    },
  },
  data: () => ({
    switch1: true,
    // dialog: false,
  }),
};
</script>

<style></style>
