<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="outlined"
        :dense="dense"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      locale="th"
      no-title
      scrollable
      @input="
        menu = false;
        $refs.menu.save(date);
      "
    >
      <!-- <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn> -->
    </v-date-picker>
  </v-menu>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
export default {
  name: "cDateTimePicker",
  mixins: [globalFunctionMixin],

  model: { prop: "value", event: "input" },

  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String],
      default: "",
    },
    dense: {
      type: [Boolean],
      default: false,
    },
    outlined: {
      type: [Boolean],
      default: false,
    },
  },
  computed: {
    computedDateFormatted() {
      this.$emit("input", this.$moment(this.date).format("YYYY-MM-DD"));
      return this.getAbbrFullYearPeriodThai(
        this.$moment(this.date).format("YYYY-MM-DD")
      );
      // return this.$moment(this.date)
      //   .add(543, "y")
      //   .format("DD-MMMM-YYYY");
    },
  },
  methods: {},
  mounted() {
    if (this.value != null && this.value != "") this.date = this.value;
  },
  data: () => ({
    date: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    ).toISOString(),

    menu: false,
  }),
};
</script>

<style></style>
