<template>
  <div class="Export_Page">
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>เปิด/ปิดงวด</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-card class="mx-auto ml-5 mr-5 mt-5" color="grey lighten-5">
      <v-toolbar flat color="blue-grey lighten-5">
        <strong class="black--text"
          >{{ HostName }}
          <v-icon color="pink" class="ml-2 mr-2">mdi-cog</v-icon></strong
        >
        <strong class="black--text">งวด วันที่ {{ period_th() }}</strong>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-layout row>
          <v-col xl="6" lg="6" md="6" cols="12">
            <v-list two-line dense>
              <v-subheader>งวดวันที่</v-subheader>

              <v-list-item @click="OnClickEdit('Period')">
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-calendar-clock-outline
                  </v-icon>
                </v-list-item-icon>
                <!-- งวดที่ -->
                <v-list-item-content>
                  <v-list-item-title
                    ><strong class="green--text text--darken-3">{{
                      getAbbrFullYearPeriodThai(Current.Period)
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3"
                    ><span v-if="Edit['Period'] != Current.Period"
                      >เปลี่ยนเป็น
                      <strong class="red--text">{{
                        getAbbrFullYearPeriodThai(Edit.Period)
                      }}</strong></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>
              <!-- เปิด / ปิด  -->
              <v-subheader>เปิด/ปิด</v-subheader>
              <v-list-item @click="OnClickEdit('open-close')">
                <v-list-item-icon>
                  <v-icon color="indigo">
                    {{
                      Current.IsOpen == 0 ? "mdi-lock" : "mdi-lock-open-variant"
                    }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="pt-1"
                    ><strong
                      :class="[
                        Current.IsOpen == 0
                          ? 'red--text text--darken-3'
                          : 'green--text text--darken-3',
                      ]"
                      >{{
                        Current.IsOpen == 0 ? "ปิดรับแทง" : "เปิดรับแทง"
                      }}</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3 pt-1"
                    ><span v-if="Edit['IsOpen'] != Current.IsOpen"
                      >เปลี่ยนเป็น
                      <strong class="red--text">{{
                        Edit.IsOpen == 0 ? "ปิดรับแทง" : "เปิดรับแทง"
                      }}</strong></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>
              <!-- เวลาเปิด  -->
              <v-subheader
                >เวลา
                <strong class="green--text ml-1">เปิด</strong></v-subheader
              >
              <v-list-item @click="OnClickEdit('OpenTime')">
                <v-list-item-icon>
                  <v-icon color="green darken-1">
                    mdi-timer-plus-outline
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    ><strong class="green--text text--darken-3"
                      >{{
                        getAbbrFullYearPeriodThai(
                          Current.OpenTime.split(" ")[0]
                        )
                      }}
                      เวลา
                      {{
                        Current.OpenTime == ""
                          ? ""
                          : Current.OpenTime.split(" ")[1].substring(0, 5)
                      }}
                      น.
                      {{
                        Current.IsAutoOpen == "0" ? "" : "(อัตโนมัติ)"
                      }}</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3"
                    ><span
                      v-if="
                        Edit['OpenTime'] != Current.OpenTime ||
                          Edit['IsAutoOpen'] != Current.IsAutoOpen
                      "
                      >เปลี่ยนเป็น
                      <strong class="red--text"
                        >{{
                          getAbbrFullYearPeriodThai(Edit.OpenTime.split(" ")[0])
                        }}
                        เวลา
                        {{
                          Edit.OpenTime == ""
                            ? ""
                            : Edit.OpenTime.split(" ")[1].substring(0, 5)
                        }}
                        น.
                        {{
                          Edit.IsAutoOpen == "0" ? "" : "(อัตโนมัติ)"
                        }}</strong
                      ></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>
              <!-- เวลา ปิด -->
              <v-subheader
                >เวลา
                <strong class="red--text ml-1">ปิด แสดง</strong></v-subheader
              >
              <v-list-item @click="OnClickEdit('CloseTime')">
                <v-list-item-icon>
                  <v-icon color="red">
                    mdi-timer-remove-outline
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    ><strong class="black--text"
                      >{{
                        getAbbrFullYearPeriodThai(
                          Current.CloseTime.split(" ")[0]
                        )
                      }}
                      เวลา
                      {{
                        Current.CloseTime == ""
                          ? ""
                          : Current.CloseTime.split(" ")[1].substring(0, 5)
                      }}
                      น.</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3"
                    ><span v-if="Edit['CloseTime'] != Current.CloseTime"
                      >เปลี่ยนเป็น
                      <strong class="red--text"
                        >{{
                          getAbbrFullYearPeriodThai(
                            Edit.CloseTime.split(" ")[0]
                          )
                        }}
                        เวลา
                        {{
                          Edit.CloseTime == ""
                            ? ""
                            : Edit.CloseTime.split(" ")[1].substring(0, 5)
                        }}
                        น.</strong
                      ></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <!-- เวลา ปิด -->
              <v-subheader
                >เวลา
                <strong class="red--text ml-1"
                  >ปิด อัตโนมัติ</strong
                ></v-subheader
              >
              <v-list-item @click="OnClickEdit('CloseAuto')">
                <v-list-item-icon>
                  <v-icon color="red">
                    mdi-timer-remove
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="pt-1"
                    ><strong class="black--text">{{
                      Current.IsAutoClose == "0"
                        ? "ปิดด้วยตัวเอง"
                        : Current.AutoCloseTime.split(" ")[0]
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3 pt-1"
                    ><span
                      v-if="
                        Edit['AutoCloseTime'] != Current.AutoCloseTime ||
                          Edit['IsAutoClose'] != Current.IsAutoClose
                      "
                      >เปลี่ยนเป็น
                      <strong class="red--text"
                        >{{
                          getAbbrFullYearPeriodThai(
                            Edit.AutoCloseTime.split(" ")[0]
                          )
                        }}
                        เวลา
                        {{
                          Edit.AutoCloseTime == ""
                            ? ""
                            : Edit.AutoCloseTime.split(" ")[1].substring(0, 5)
                        }}
                        น.
                        {{ Edit.IsAutoClose == "0" ? "" : "(อัตโนมัติ)" }}
                      </strong></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider inset></v-divider>

              <!-- สถานะ -->
              <!-- <v-subheader>สถานะ</v-subheader>
              <v-list-item @click="OnClickEdit('Status')">
                <v-list-item-icon>
                  <v-icon color="indigo">
                    mdi-credit-card-fast
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    ><strong class="green--text text--darken-3"
                      >ระหว่างงวด</strong
                    ></v-list-item-title
                  >
                  <v-list-item-subtitle class="mt-3"
                    ><span v-if="Edit['Status'] != ''"
                      >เปลี่ยนเป็น
                      <strong class="red--text">จบงวด</strong></span
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="blue">mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item> -->

              <v-divider inset></v-divider>
            </v-list>

            <v-toolbar flat color="grey lighten-5">
              <v-spacer></v-spacer>

              <v-btn color="red darken-1" dark @click="ResetData()">
                <v-icon class="mr-2">mdi-refresh</v-icon> รีเซ็ต</v-btn
              >
              <v-btn color="teal" class="ml-2" dark @click="SubmitData()">
                <v-icon left>mdi-content-save-outline</v-icon>
                บันทึก
              </v-btn>
            </v-toolbar>
          </v-col>
          <!-- activity -->
          <v-col xl="6" lg="6" md="6" cols="12" class="scroll">
            <v-timeline align-top dense>
              <v-timeline-item color="pink" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>5pm</strong>
                  </v-col>
                  <v-col>
                    <strong>New Icon</strong>
                    <div class="text-caption">
                      Mobile App
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="teal lighten-3" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>3-4pm</strong>
                  </v-col>
                  <v-col>
                    <strong>Design Stand Up</strong>
                    <div class="font_s mb-2">
                      Hangouts
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="pink" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>12pm</strong>
                  </v-col>
                  <v-col>
                    <strong>Lunch break</strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="teal lighten-3" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>9-11am</strong>
                  </v-col>
                  <v-col>
                    <strong>Finish Home Screen</strong>
                    <div class="text-caption">
                      Web App
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-layout>
      </v-card-text>
    </v-card>
    <PeriodSettingDlg
      v-model="showPeriodSettingDlg"
      :OldPeriod="Current.Period"
      @NewStatus="OnPeriodSettingChange"
    ></PeriodSettingDlg>
    <OpenCloseDlg
      v-model="showOpenCloseDlg"
      :OldStatus="parseInt(Current.IsOpen)"
      @NewStatus="OnOpenCloseChange"
    ></OpenCloseDlg>
    <OpenTimeDlg
      v-model="showOpenTimeDlg"
      :OldOpenDateTime="Current.OpenTime"
      :OldIsAutoOpen="parseInt(Current.IsAutoOpen)"
      @NewOpenDateTime="OnOpenDateTimeChange"
      @NewIsAutoOpen="OnIsAutoOpenChange"
    ></OpenTimeDlg>
    <CloseTimeDlg
      v-model="showCloseTimeDlg"
      :OldCloseDateTime="Current.CloseTime"
      @NewCloseTime="OnCloseTimeChange"
    ></CloseTimeDlg>
    <CloseAutoDlg
      v-model="showCloseAutoDlg"
      :OldAutoCloseDateTime="Current.AutoCloseTime"
      :OldIsAutoClose="parseInt(Current.IsAutoClose)"
      @NewAutoCloseDateTime="OnAutoCloseDateTimeChange"
      @NewIsAutoClose="OnIsAutoCloseChange"
    ></CloseAutoDlg>
    <StatusDlg v-model="showStatusDlg"></StatusDlg>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import PeriodSettingDlg from "@/components/Data/Period_Setting/PeriodDlg";
import OpenCloseDlg from "@/components/Data/Period_Setting/OpenCloseDlg";
import OpenTimeDlg from "@/components/Data/Period_Setting/OpenTimeDlg";
import CloseTimeDlg from "@/components/Data/Period_Setting/CloseTimeDlg";
import CloseAutoDlg from "@/components/Data/Period_Setting/CloseAutoDlg";
import StatusDlg from "@/components/Data/Period_Setting/StatusDlg";
// import ExportPagesDatatable from "@/components/Data/Export_Page/Dashboard/Datatable";

export default {
  name: "Export_Pages",
  mixins: [globalFunctionMixin],
  components: {
    PeriodSettingDlg,
    OpenCloseDlg,
    OpenTimeDlg,
    CloseTimeDlg,
    CloseAutoDlg,
    StatusDlg,
  },

  mounted() {
    this.Current.Period = sessionStorage.getItem("period");
    this.ckLogin();
    window.scrollTo(0, 0);
    this.fetch_data();
  },
  methods: {
    async fetch_data() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
      };
      this.$store.dispatch("period/get_period_detail", credentials).then(
        (response) => {
          this.Current.CloseTime = response.PeriodDetail["CloseTime"];
          this.Current.IsOpen = response.PeriodDetail["IsOpen"];
          this.Current.OpenTime = response.PeriodDetail["OpenTime"];
          this.Current.IsAutoOpen = response.PeriodDetail["IsAutoOpen"];
          this.Current.AutoCloseTime = response.PeriodDetail["AutoCloseTime"];
          this.Current.IsAutoClose = response.PeriodDetail["IsAutoClose"];
          this.Current.IsDefault = response.PeriodDetail["IsDefault"];
          this.Current.Status = response.PeriodDetail["IsDefault"];

          this.OnClearEdit();

          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async save_data() {
      //EDIT

      this.Sending = false;
      console.log("this.Sending");
      console.log(this.Sending);
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        NewPeriodDT:
          this.Edit.Period != this.Current.Period ? this.Edit.Period : "",
        NewIsOpen:
          this.Edit.IsOpen != this.Current.IsOpen ? this.Edit.IsOpen : "",
        NewOpenTime:
          this.Edit.OpenTime != this.Current.OpenTime ? this.Edit.OpenTime : "",
        NewIsAutoOpen:
          this.Edit.IsAutoOpen != this.Current.IsAutoOpen
            ? this.Edit.IsAutoOpen
            : "",
        NewCloseTime:
          this.Edit.CloseTime != this.Current.CloseTime
            ? this.Edit.CloseTime
            : "",
        NewIsAutoClose:
          this.Edit.IsAutoClose != this.Current.IsAutoClose
            ? this.Edit.IsAutoClose
            : "",
        NewAutoCloseTime:
          this.Edit.AutoCloseTime != this.Current.AutoCloseTime ? "" : "",
      };

      console.log(credentials);
      //   this.$store.dispatch("period/get_period_detail", credentials).then(
      //     (response) => {
      //       this.Current.CloseTime = response.PeriodDetail["CloseTime"];
      //       this.Current.IsOpen = response.PeriodDetail["IsOpen"];
      //       this.Current.OpenTime = response.PeriodDetail["OpenTime"];
      //       this.Current.IsAutoOpen = response.PeriodDetail["IsAutoOpen"];
      //       this.Current.AutoCloseTime = response.PeriodDetail["AutoCloseTime"];
      //       this.Current.IsAutoClose = response.PeriodDetail["IsAutoClose"];
      //       this.Current.IsDefault = response.PeriodDetail["IsDefault"];
      //       this.Current.Status = response.PeriodDetail["IsDefault"];

      //       this.OnClearEdit();

      //       console.log(response);
      //     },
      //     (error) => {
      //       console.log(error);
      //     }
      //   );
    },
    ResetData() {
      this.$confirm("กดยืนยันเพื่อล้างข้อมูลที่กำลังจะแก้ไข")
        .then(() => {
          this.OnClearEdit();
        })
        .catch(() => {});
    },
    SubmitData() {
      console.log(this.Sending);
      if (this.Sending == true) return;

      this.$confirm("กดยืนยันเพื่อบันทึก")
        .then(() => {
          this.Sending = true;
          this.save_data();
        })
        .catch(() => {});
    },
    OnClearEdit() {
      this.Edit["Period"] = this.Current.Period;
      this.Edit["IsOpen"] = this.Current.IsOpen;
      this.Edit["IsAutoOpen"] = this.Current.IsAutoOpen;
      this.Edit["OpenTime"] = this.Current.OpenTime;
      this.Edit["CloseTime"] = this.Current.CloseTime;
      this.Edit["IsAutoClose"] = this.Current.IsAutoClose;
      this.Edit["AutoCloseTime"] = this.Current.AutoCloseTime;
      this.Edit["Status"] = this.Current.Status;
    },
    OnClickEdit(EditType) {
      switch (EditType) {
        case "Period":
          this.showPeriodSettingDlg = true;
          break;
        case "open-close":
          this.showOpenCloseDlg = true;
          break;
        case "OpenTime":
          this.showOpenTimeDlg = true;
          break;
        case "CloseTime":
          this.showCloseTimeDlg = true;
          break;
        case "CloseAuto":
          this.showCloseAutoDlg = true;
          break;
        case "Status":
          this.showStatusDlg = true;
          break;
      }
      //   console.log(EditType);
    },
    OnPeriodSettingChange(data) {
      this.Edit.Period = data;
    },
    OnOpenCloseChange(data) {
      this.Edit.IsOpen = data;
    },
    OnOpenDateTimeChange(data) {
      this.Edit.OpenTime = data[0] + " " + data[1];
    },
    OnIsAutoOpenChange(data) {
      this.Edit.IsAutoOpen = data;
    },
    OnCloseTimeChange(data) {
      this.Edit.CloseTime = data[0] + " " + data[1];
    },
    OnAutoCloseDateTimeChange(data) {
      this.Edit.AutoCloseTime = data[0] + " " + data[1];
    },
    OnIsAutoCloseChange(data) {
      this.Edit.IsAutoClose = data;
    },

    period_th() {
      return this.getFullPeriodThai(sessionStorage.getItem("period"));
    },
  },

  data: () => ({
    Sending: false,

    HostName: sessionStorage.getItem("nickname"),
    Edit: {
      Period: "",
      IsOpen: "",
      OpenTime: "",
      IsAutoOpen: "",
      CloseTime: "",
      IsAutoClose: "",
      AutoCloseTime: "",
      Status: "",
    },
    showPeriodSettingDlg: false,
    showOpenCloseDlg: false,
    showOpenTimeDlg: false,
    showCloseTimeDlg: false,
    showCloseAutoDlg: false,
    showStatusDlg: false,
    Current: {
      Period: "",
      CloseTime: "",
      IsAutoClose: "",
      AutoCloseTime: "",
      IsAutoOpen: "",
      IsDefault: "",
      IsOpen: 0,
      OpenTime: "",
      Status: "",
    },
  }),
};
</script>

<style scoped>
.scroll {
  flex-grow: 1;
  overflow-y: scroll;
  height: 80vh;
}
.font_s {
  font-size: 0.8em !important;
}
</style>
