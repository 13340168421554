<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title>เปลี่ยนงวดวันที่ จาก {{ OldStatus }}</v-card-title>
      <v-card-text>
        <v-switch
          v-model="switch1"
          :label="`Switch 1: ${switch1.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="close()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OpenCloseDlg",
  components: {},

  props: {
    value: Boolean,
    OldStatus: String,
  },
  computed: {
    show: {
      get() {
        console.log("init dlg");
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {
    close() {
      this.$emit("input", false);
    },
  },
  data: () => ({
    switch1: true,
    // dialog: false,
  }),
};
</script>

<style></style>
