<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title
        >เปลี่ยนเวลาปิด จาก
        {{ getAbbrFullYearPeriodThai(OldCloseDateTime.split(" ")[0]) }} เวลา
        {{
          OldCloseDateTime == ""
            ? ""
            : OldCloseDateTime.split(" ")[1].substring(0, 5)
        }}
        น. เป็น</v-card-title
      >
      <v-card-text>
        <v-row class="pt-0 mt-0">
          <v-col cols="6"
            ><CloseDatePicker v-model="EditCloseDate"></CloseDatePicker
          ></v-col>
          <v-col cols="6"
            ><CloseTimePicker v-model="EditCloseTime"></CloseTimePicker
          ></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">ยกเลิก</v-btn>
        <v-btn text @click="edit()">แก้ไข</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import CloseDatePicker from "@/components/Controls/cDatePicker";
import CloseTimePicker from "@/components/Controls/cTimePicker";

export default {
  name: "PeriodSettingDlg",
  mixins: [globalFunctionMixin],
  components: {
    CloseDatePicker,
    CloseTimePicker,
  },

  props: {
    value: Boolean,
    OldCloseDateTime: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  methods: {
    close() {
      this.$emit("input", false);
    },
    edit() {
      this.$emit(
        "NewCloseTime",
        new Array(this.EditCloseDate, this.EditCloseTime)
      );
      this.$emit("input", false);
    },
  },
  data: () => ({
    // dialog: false,
    EditCloseDate: new Date().toISOString().substr(0, 10),
    EditCloseTime: "14:00",
  }),
};
</script>

<style></style>
